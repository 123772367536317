import React from 'react'
import Accordion from '../Accordion'

const Faq = ({ questions }) => {
  return (
    <div className="mt-[3.75rem] pb-[60px]">
      <div className="text-[32px] px-[41px] text-[#1F2933] font-[600] leading-[42px]">
        FAQs
      </div>
      <div className="mt-[44px] px-[31px]">
        {questions?.length > 0 &&
          questions.map((question, index) => (
            <Accordion
              title={question.title}
              answer={question.answer}
              index={index}
            />
          ))}
      </div>
    </div>
  )
}

export default Faq
