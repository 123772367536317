import React, { memo, useEffect, useState } from 'react'

import Layout from '../../components/Layout'
import Form from './form'

import './style.css'

import { getUrlParams } from '../../utils/helpers/common'
import { trackEvent } from '../../analytics'

import { EVENTS } from '../../analytics/events'

const Register = () => {
  const [urlParams, setUrlParams] = useState(null)
  useEffect(() => {
    const urlParams = getUrlParams()
    setUrlParams(urlParams)
    trackEvent(EVENTS.PAGE_VIEW, {
      screenName: 'Register Screen',
      ...urlParams,
    })
  }, [])

  return (
    <Layout>
      <div className="container">
        <div className="home-page-container">
          <div className="content">
            {urlParams?.plan_master_id &&
            urlParams?.plan_package_duration_rel_id &&
            urlParams?.utm_source ? (
              <Form />
            ) : (
              <p className="text-red-800">Invalid url or link Expired</p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default memo(Register)
