// date format will be in dayjs
export const getDateStr = (date = {}) => {
  return `${date['$y']}-${date['$M'] + 1}-${date['$D']}`
}

export const parseQueryString = (queryString) => {
  var params = {}
  var queryStringWithoutQuestionMark = queryString.substring(1) // Remove the leading '?'
  var keyValuePairs = queryStringWithoutQuestionMark.split('&')

  for (var i = 0; i < keyValuePairs.length; i++) {
    var keyValuePair = keyValuePairs[i].split('=')
    var key = decodeURIComponent(keyValuePair[0])
    var value =
      keyValuePair.length > 1 ? decodeURIComponent(keyValuePair[1]) : null

    params[key] = value
  }

  return params
}

export const getUrlParams = () => {
  if (typeof window !== 'undefined') {
    return parseQueryString(window.location.search)
  } else {
    return {}
  }
}
