import React, { useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'

// import LowerPortionImage from '../../assets/images/request-lower.png'
// import UpperPortionImage from '../../assets/images/request-upper.png'
import PhoneImage from '../../assets/images/phone.png'

import { requestCallback } from '../../api'
import constants from '../../constants'
import { programNames } from '../../constants/common'

const RequestCallback = (props) => {
  const { careProgramType = constants.CARE_PROGRAM_TYPE.ANAYA } = props
  const initialValues = { name: '', email: '', phoneNo: '' }
  const [formValues, setFormValues] = useState(initialValues)
  const [touchedFields, setTouchedFields] = useState({
    name: false,
    email: false,
    phoneNo: false,
  })
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setTouchedFields({ ...touchedFields, [name]: true })
    setFormValues(() => {
      validate({ ...formValues, [name]: value })
      return { ...formValues, [name]: value }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const payload = {
      name: formValues?.name,
      email: formValues?.email,
      phone_number: formValues?.phoneNo,
      campaign_type: programNames[careProgramType],
    }
    const response = await requestCallback(payload)

    if (response?.response === 'success') {
      setFormValues(initialValues)
      setTouchedFields({
        name: false,
        email: false,
        phoneNo: false,
      })
      setIsSubmit(true)
    }
  }

  useEffect(() => {
    console.log(formErrors)
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues)
    }
  }, [formErrors])

  const validate = (values) => {
    const errors = {}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
    if (!values.name) {
      errors.name = 'name is required!'
    }
    if (!values.email) {
      errors.email = 'Email is required!'
    } else if (!regex.test(values.email)) {
      errors.email = 'This is not a valid email format!'
    }

    if (!values.phoneNo) {
      errors.phoneNo = 'phone number is required!'
    } else if (values?.phoneNo?.match(/\d/g)?.length !== 10) {
      errors.phoneNo = 'This is not a valid phone number!'
    }
    setFormErrors(errors)
    return errors
  }

  const isFormValid = () => {
    return (
      !formErrors?.email &&
      touchedFields?.email &&
      !formErrors?.name &&
      touchedFields?.name &&
      !formErrors?.phoneNo &&
      touchedFields?.phoneNo
    )
  }

  useEffect(() => {
    if (isSubmit) {
      console.log('isSubmit', isSubmit)
      setTimeout(() => {
        setIsSubmit(false)
      }, 3000)
    }
  }, [isSubmit])
  // bg-[#91639C]
  return (
    <div className="mt-[40px]">
      <Snackbar
        open={isSubmit}
        autoHideDuration={3000}
        message="Form Submitted Succesfully"
      />
      <div className="request-callback-bg">
        {/* <img src={UpperPortionImage} width="100%" /> */}
        <div className="px-[2rem]">
          <div className="text-[32px] mt-[-5px] text-[#FFFFFF] font-[300] leading-[42px]">
            Request callback
          </div>
          <p className="text-[16px] text-[#FFFFFF] mt-[17px] font-[300] leading-[28px] text-opacity-60">
            {`Have any doubts? Request a callback and our team of care ${careProgramType === constants.CARE_PROGRAM_TYPE.ANAYA ? 'specialists' : 'experts'}
            will help you get your journey started`}
          </p>
        </div>

        <form
          className="flex flex-col items-center px-[1rem] mt-[60px] w-full"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="flex flex-col w-[100%]">
            <span className="text-white text-[17px] font-[500] leading-[30px]">
              Your Name*
            </span>
            <input
              value={formValues.name}
              onChange={handleChange}
              className="w-full mt-[1rem] px-4 py-4 border-1 border-[#ccc] rounded-[10px]"
              placeholder="Type your name"
              name="name"
            ></input>
            {touchedFields.name && (
              <div className="text-[#fadada]">{formErrors.name}</div>
            )}
          </div>

          <div className="flex flex-col mt-[1.5rem] w-[100%]">
            <span className="text-white text-[17px] font-[500] leading-[30px]">
              Email Address*
            </span>
            <input
              value={formValues.email}
              onChange={handleChange}
              className="w-full mt-[1rem] px-4 py-4 border-1 border-[#ccc] rounded-[10px]"
              placeholder="Enter your email"
              name="email"
            ></input>
            {touchedFields.email && (
              <div className="text-[#fadada]">{formErrors.email}</div>
            )}
          </div>
          <div className="flex flex-col  mt-[1.5rem]  w-[100%]">
            <span className="text-white text-[17px] font-[500] leading-[30px]">
              Phone Number*
            </span>
            <input
              value={formValues.phoneNo}
              onChange={handleChange}
              className="w-full mt-[1rem] px-4 py-4  border-1 border-[#ccc] rounded-[10px]"
              placeholder="Enter your phone number"
              name="phoneNo"
            ></input>
            {touchedFields.phoneNo && (
              <div className="text-[#fadada]">{formErrors.phoneNo}</div>
            )}
          </div>
          <div className="w-[100%] text-center relative mt-[24px]">
            <button
              type="submit"
              className={`bg-[#F15223] w-full text-[17px] font-[500] text-white p-3 border-1 p w-[328px] border-1 rounded-[16px] p-[17px] ${
                !isFormValid()
                  ? 'pointer-events-none cursor-not-allowed opacity-50'
                  : ''
              }`}
            >
              Request Callback
            </button>
          </div>
          {/* {isSubmit && (
            <div className="mt-5 text-[white]">Form Submitted Succesfully</div>
          )} */}
        </form>
        <button className="flex items-center mt-[32px]">
          <img src={PhoneImage} />
        </button>
      </div>
      {/* <img src={LowerPortionImage} width="100%" /> */}
    </div>
  )
}

export default RequestCallback
