import React from 'react'

import RecoveryJourneyImg from '../../../assets/images/programs/prostate-care/prostate-care-recovery-journey.png'

const RecoveryJourney = () => {
  return (
    <section className="mt-[60px]">
      <img src={RecoveryJourneyImg} alt="prostate-care-recovery-journy-image" />
    </section>
  )
}

export default RecoveryJourney
