import constants from '../constants'

export const deeplinks = {
  'anaya-care': 'https://mytatva.page.link/SIGRIMA',
  'bilypsa-care': 'https://mytatva.page.link/BILYPSA',
  'prostate-care': 'https://mytatva.page.link/PROSTATE',
  'akumentis-care': 'https://mytatva.page.link/AKUMENTIS',
}

export const programNames = {
  [constants.CARE_PROGRAM_TYPE.ANAYA]: 'Anaya Care Program',
  [constants.CARE_PROGRAM_TYPE.BILYPSA]: 'Ayushman Liver Care Program',
  [constants.CARE_PROGRAM_TYPE.PROSTATE]: 'Prostate Care Program',
  [constants.CARE_PROGRAM_TYPE.AKUMENTIS]: 'Akumentis Care Program',
}
