import React, { Suspense, memo, useEffect, useRef, useState } from 'react'

import Layout from '../../../components/Layout'

import './style.css'

import { getUrlParams } from '../../../utils/helpers/common'
import { trackEvent } from '../../../analytics'

import { EVENTS } from '../../../analytics/events'
import OurProgram from '../../../components/OurProgram'
import Welcome from '../../../components/Welcome'
import ZydusImage from '../../../assets/images/zydus.png'
import moveToStore from '../../../utils/helpers/deviceType'
import ExpertiseImage from '../../../assets/images/expertise.png'
import TrustedImage from '../../../assets/images/trusted.png'
import constants from '../../../constants'
import WelcomeAkumentise from '../../../assets/images/welcome-Akumentis.png'
import SetupMedicine from '../../../components/SetupMedicine'
import WellBeing from '../../../components/WellBeing'
import EducationalContent from '../../../components/EducationalContent'
import FibroeaseImage from '../../../assets/images/fibroease.png'

const SmartScale = React.lazy(() => import('../../../components/SmartScale'))
const HealthRecordBilypsa = React.lazy(
  () => import('../../../components/HealthRecordBilypsa'),
)
const HealthRecord = React.lazy(
  () => import('../../../components/HealthRecord'),
)
const HeartFeltStories = React.lazy(
  () => import('../../../components/HeartFeltStories'),
)

const OncoRecommended = React.lazy(
  () => import('../../../components/OncoRecommended'),
)

const CareCoins = React.lazy(() => import('../../../components/CareCoins'))
const StartJourney = React.lazy(
  () => import('../../../components/StartJourney'),
)
const Faq = React.lazy(() => import('../../../components/Faq'))
const RequestCallback = React.lazy(
  () => import('../../../components/RequestCallback'),
)
const Slider = React.lazy(() => import('../../../components/Slider'))

const MeetCareNavigator = React.lazy(
  () => import('../../../components/MeetCareNavigator'),
)

// const BookLabImage = React.lazy(
//   () => import('../../../assets/images/book-lab.png'),
// )

// const ConsultPsychologistImage = React.lazy(
//   () => import('../../../assets/images/consult-psychologist.png'),
// )

// const ConsultNutritionstImage = React.lazy(
//   () => import('../../../assets/images/consult-nutrinist.png'),
// )

// const CareProductImage = React.lazy(
//   () => import('../../../assets/images/care-product.png'),
// )

const AkumentisCarePage = () => {
  const [urlParams, setUrlParams] = useState(null)
  const innerRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(document.body.scrollTop)
  const [addbottom, setBottom] = useState()
  // const location = useLocation()
  // const isBylypsa = location?.pathname.includes('bilypsa-care')
  useEffect(() => {
    const urlParams = getUrlParams()
    setUrlParams(urlParams)
    trackEvent(EVENTS.PAGE_VIEW, {
      screenName: 'Programs Screen',
      pageName: 'Akumentis care',
      ...urlParams,
    })
  }, [])

  useEffect(() => {
    const div = innerRef.current
    // subscribe event
    div.addEventListener('scroll', handleOnScroll)
    return () => {
      // unsubscribe event
      div.removeEventListener('scroll', handleOnScroll)
    }
  }, [])

  const handleOnScroll = (e) => {
    // NOTE: This is for the sake of demonstration purpose only.
    // Doing this will greatly affect performance.
    setScrollTop(e.target.scrollTop)
    e.target.scrollTop >= 625 ? setBottom(true) : setBottom(false)
  }

  return (
    <Layout>
      <div
        className="container h-[100vh]"
        style={{ padding: 0, overflow: 'auto' }}
        ref={innerRef}
      >
        <div className="anaya-page-container">
          <div className="akumentis-content content min-w-sm max-w-lg">
            <Welcome
              careProgramType={constants.CARE_PROGRAM_TYPE.AKUMENTIS}
              welcomeImage={WelcomeAkumentise}
              storeUrl={'https://mytatva.page.link/AKUMENTIS'}
            ></Welcome>
            {
              <div
                className="bg-[#875ECB] h-[335px] z-5 relative top-[-57px]  flex flex-col pt-[40px] px-[40px] align-middle justify-center rounded-[35px]"
                style={{
                  background: 'linear-gradient(to left, #572D9E, #875ECB)',
                }}
              >
                <>
                  <div className="flex flex-row  items-center">
                    <span>
                      <img src={TrustedImage} className="h-[80px] w-[80px]" />
                    </span>
                    <span className="font-[600]  pl-5 text-[16px] leading-[28px] text-white">
                      Trusted by 10,000+ customers
                    </span>
                  </div>
                  <div className="flex flex-row  items-center  mt-[50px]">
                    <span>
                      <img src={ExpertiseImage} className="h-[80px] w-[80px]" />
                    </span>
                    <span className="font-[600]  pl-5 text-[16px] leading-[28px] text-white">
                      Created by field specialist
                    </span>
                  </div>
                </>
              </div>
            }

            <OurProgram
              careProgramType={constants.CARE_PROGRAM_TYPE.AKUMENTIS}
            ></OurProgram>
            <Suspense fallback={<div>Loading...</div>}>
              <SetupMedicine />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <WellBeing />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <EducationalContent />
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
              <HeartFeltStories
                careProgramType={constants.CARE_PROGRAM_TYPE.AKUMENTIS}
              ></HeartFeltStories>
            </Suspense>
            {/* <Suspense fallback={<div>Loading...</div>}>
              <MeetCareNavigator></MeetCareNavigator>
            </Suspense> */}
            {/* <Suspense fallback={<div>Loading...</div>}>
              <OncoRecommended></OncoRecommended>
            </Suspense> */}
            <Suspense fallback={<div>Loading...</div>}>
              <StartJourney
                careProgramType={constants.CARE_PROGRAM_TYPE.AKUMENTIS}
              ></StartJourney>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Faq
                questions={[
                  {
                    title: 'Who should download this app?',
                    answer:
                      'We highly recommend that the person with Uterine Fibroids downloads this app.',
                  },
                  {
                    title:
                      'Is there an additional fee for this support program?',
                    answer:
                      'No, this is a free support program as part of your Fibroid Care Program. There are however certain services within the support program which follow a pay as you go model - for eg., buying devices, booking a test, etc.',
                  },
                  {
                    title: 'How secure is my data?',
                    answer:
                      'All data is secure and stored in encrypted servers. Other than data that is willingly shared for the purposes of availing the service, no other data of the patient is accessed.',
                  },
                  {
                    title: 'Who is eligible for this support program',
                    answer:
                      'All those who have been prescribed by their doctor are eligible for this program.',
                  },
                ]}
              ></Faq>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <RequestCallback
                careProgramType={constants.CARE_PROGRAM_TYPE.AKUMENTIS}
              ></RequestCallback>
            </Suspense>
            {/* <div className="">
              <div className="flex flex-col  mb-8 w-[100%]">
                <button className="bg-[#F15223] text-white p-3 border-1 rounded-lg">
                  Download MyTatva App
                </button>
              </div>
            </div> */}
            {/* {urlParams?.plan_master_id &&
            urlParams?.plan_package_duration_rel_id &&
            urlParams?.utm_source ? (
              <Form />
            ) : (
              <p className="text-red-800">Invalid url or link Expired</p>
            )} */}
            <div className="text-center  pt-[30px] pb-[200px] font-[300] text-[18px] leading-[26px]">
              Copyright © 2024 TatvaCare | All Rights Reserved
              <div className="flex flex-col justify-center items-center">
                <div>Powered by &nbsp;&nbsp;</div>
                <div>
                  <img
                    style={{ maxWidth: '175px', maxHeight: '100px' }}
                    src={FibroeaseImage}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`w-full text-center fixed z-15 left-0 bg-white bottom-gray-class ${addbottom ? 'bottom-0' : 'bottom-[-90px]'}`}
            style={{
              padding: '15px 0',
            }}
          >
            <div className="">
              <button
                onClick={() =>
                  moveToStore('https://mytatva.page.link/AKUMENTIS')
                }
                className="bg-[#F15223] text-[18px] font-[600] text-white p-3 border-1 p max-w-[358px] w-[90%] border-1 rounded-[16px] p-[15px]"
              >
                Download MyTatva App
              </button>
            </div>
            {/* <button
              className="bg-[#F15223] text-[18px] font-[600] text-white p-3 border-1 rounded-lg w-[358px] border-1 rounded-[16px] p-[15px]"
              onClick={() => moveToStore()}
            >
              Download MyTatva App
            </button> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default memo(AkumentisCarePage)
