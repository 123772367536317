import React, { memo } from 'react'
import Layout from '../../components/Layout'

import './style.css'

const SupportPage = () => {
  return (
    <Layout>
      <div
        className="container h-[100vh]"
        style={{ padding: 0, overflow: 'auto' }}
      >
        <div className="anaya-page-container">
          <div className="content min-w-sm max-w-lg">
            <div className="text-[24px] font-[500] text-[#1F2933] leading-[32px]">
              Download MyTatva for a hassle free experience
            </div>

            <div className="mt-[125px] flex items-center justify-center">
              <img
                src={require('../../assets/images/Logo.png')}
                className="w-[200px] h-[200px]"
                style={{ position: 'relative', zIndex: 2 }}
              />
            </div>

            <div className="mt-[40px] w-full">
              <div className="download-container">
                <a
                  href="https://mytatvaprodstorage.blob.core.windows.net/myt-apk-ipa/myTatva.apk"
                  className="download-button py-[15px] px-[30px] block"
                >
                  Install MyTatva App
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default memo(SupportPage)
