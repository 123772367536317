import React from 'react'

import ExpertiseImage from '../../../assets/images/expertise.png'
import TrustedImage from '../../../assets/images/trusted.png'

const Highlights = () => {
  return (
    <section
      className="bg-[#875ECB] z-5 mt-[40px] flex flex-col py-[40px] px-[40px]"
      style={{
        background: 'linear-gradient(to left, #572D9E, #875ECB)',
      }}
    >
      <>
        <div className="flex flex-row  items-center">
          <img src={ExpertiseImage} className="h-[80px] w-[80px]" />
          <p className="font-[600]  pl-5 text-[16px] leading-[28px] text-white">
            Crafted with <br />
            specialists
          </p>
        </div>
        <div className="flex flex-row  items-center  mt-[50px]">
          <img src={TrustedImage} className="h-[80px] w-[80px]" />
          <p className="font-[600]  pl-5 text-[16px] leading-[28px] text-white">
            Trusted by 10,000+ <br />
            customers
          </p>
        </div>
      </>
    </section>
  )
}

export default Highlights
