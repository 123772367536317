import React from 'react'
import SetupMedicineImage from '../../assets/images/setupMedicine.png'

const SetupMedicine = () => {
  return (
    <div className="mt-[40px] px-[40px] pb-[24px]">
      <div className="mt-[8px] relative right-5">
        <img src={SetupMedicineImage} className="w-358" />
      </div>
      <div className="pl-[1.5px] mt-[12px]">
        <div className="text-[20px] text-[#1F2933] font-[600] leading-[26px]">
          Setup Medicine
        </div>
        <p className="text-[16px] text-[#5A6774] mt-3 font-[300] leading-[24px]">
          Track your daily Fibroease tablet intake, set schedules and receive
          reminders
        </p>
      </div>
    </div>
  )
}

export default SetupMedicine
