import ReactGA4 from 'react-ga4'

const googleAnalyticsUtils = {
  initializeGA4: () => {
    const trackingCode = process.env.REACT_APP_GA_TRACKING_CODE
    ReactGA4.initialize(trackingCode)
  },

  trackPageview: (pathname, title) => {
    // ReactGA4.pageview(pathname)
    ReactGA4.send({ hitType: 'pageview', page: pathname, title })
  },

  trackEvent: (eventName, attributes) => {
    ReactGA4.event({
      category: 'User Interaction',
      action: eventName,
      // Must add label while pushing the events
      ...attributes,
    })
  },

  setUserAttributes: (userId, userProperties) => {
    ReactGA4.set({
      userId,
      ...userProperties,
    })
  },
}

export default googleAnalyticsUtils
