import React from 'react'

import ZydusImage from '../../../assets/images/zydus.png'

const Copyrights = () => {
  return (
    <section className="text-center pt-[30px] pb-[200px] font-[300] text-[12px] leading-[26px]">
      Copyright © 2024 TatvaCare | All Rights Reserved
      <div className="flex justify-center items-center">
        <span>Powered by &nbsp;&nbsp;</span>
        <span>
          <img src={ZydusImage} />
        </span>
      </div>
    </section>
  )
}

export default Copyrights
