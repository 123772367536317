import React from 'react'

import Faq from '../../../components/Faq'
import Accordion from '../../../components/Accordion'

const Faqs = () => {
  const faqs = [
    {
      title: 'Who should download this app?',
      answer: 'Anyone suffering from Prostate Cancer can download this app.',
    },
    {
      title: 'Is there an additional fee for this support program?',
      answer:
        'No, this is a free support program as part of your Prerak Care Program. However, certain services within the support program follow a pay-as-you-go model, such as buying devices or booking a test.',
    },
    {
      title: 'How secure is my data?',
      answer:
        'All data is secure and stored on encrypted servers. Except for data willingly shared for availing services, no other patient data is accessed.',
    },
    {
      title: 'Who is eligible for this support program?',
      answer:
        'All those who have been prescribed and have purchased Degralax are eligible for this program.',
    },
  ]

  return (
    <div className="mt-[3.75rem] pb-[60px]">
      <div className="text-[32px] px-[41px] text-[#1F2933] font-[600] leading-[42px]">
        FAQs
      </div>
      <div className="mt-[44px] px-[31px]">
        {faqs?.length > 0 &&
          faqs.map((question, index) => (
            <Accordion
              title={question.title}
              answer={question.answer}
              index={index}
            />
          ))}
      </div>
    </div>
  )
}

export default Faqs
