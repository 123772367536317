import React from 'react'
import EducationImage from '../../assets/images/educational-content.png'

const EducationalContent = () => {
  return (
    <div className="mt-[40px] px-[40px] pb-[24px]">
      <div className="mt-[8px] relative right-5">
        <img src={EducationImage} className="w-358" />
      </div>
      <div className="pl-[1.5px] mt-[12px]">
        <div className="text-[20px] text-[#1F2933] font-[600] leading-[26px]">
          Educational Content
        </div>
        <p className="text-[16px] text-[#5A6774] mt-3 font-[300] leading-[24px]">
          Explore comprehensive educational resources on the app and understand
          your condition better
        </p>
      </div>
    </div>
  )
}

export default EducationalContent
