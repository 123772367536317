function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

export default function moveToStore(url) {
  const os = getMobileOperatingSystem()
  if (os?.toLocaleLowerCase() === 'android') {
    window.location.href = url
  } else if (os?.toLocaleLowerCase() === 'ios') {
    window.location.href = url
  } else {
    window.location.href = 'https://www.tatvacare.in/'
  }
}
