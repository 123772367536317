// src/utils/moEngageUtils.js
import moengage from '@moengage/web-sdk'
import { getISOdate } from '../utils/helpers/date'

const moEngageUtils = {
  initializeWebEngage: () => {
    moengage.initialize({
      app_id: process.env.REACT_APP_MO_ENGAGE_APP_ID,
      cluster: process.env.REACT_APP_MO_ENGAGE_DATA_CENTER,
      debug_logs: process.env.REACT_APP_MO_ENGAGE_ENABLE_DEBUG_LOGS,
    })
  },

  trackEvent: (eventName, eventData = {}) => {
    moengage?.track_event(eventName, eventData)
  },

  setUserAttributes: (attributes) => {
    try {
      if (window.Moengage) {
        const {
          name,
          country_code = '+91',
          contact_no,
          email,
          gender,
          dob,
          ...attr
        } = attributes

        window?.Moengage?.add_unique_user_id(`${country_code}${contact_no}`)
        window?.Moengage?.add_user_name(name)
        window?.Moengage?.add_email(email)
        window?.Moengage?.add_mobile(contact_no)
        window?.Moengage?.add_gender(
          gender == 'M' ? 'Male' : gender == 'F' ? 'Female' : '',
        )
        window?.Moengage?.add_birthday(getISOdate(dob))

        for (const [key, value] of Object.entries(attr)) {
          window?.Moengage?.add_user_attribute(key, value)
        }
      } else {
        throw new Error(
          'MoEngage is not initialized. Make sure to call initializeMoEngage first.',
        )
      }
    } catch (error) {
      console.error('Error while setting user attributes:', error)
      // You can choose to handle the error here or rethrow it if needed
      throw error
    }
  },

  logout: () => {
    window?.Moengage?.destroy_session()
  },
}

export default moEngageUtils
