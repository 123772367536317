import React from 'react'
import WellBeingImage from '../../assets/images/well-being.png'

const WellBeing = () => {
  return (
    <div className="mt-[40px] px-[40px] pb-[24px]">
      <div className="mt-[8px] relative right-5">
        <img src={WellBeingImage} className="w-358" />
      </div>
      <div className="pl-[1.5px] mt-[12px]">
        <div className="text-[20px] text-[#1F2933] font-[600] leading-[26px]">
          Wellbeing Support
        </div>
        <p className="text-[16px] text-[#5A6774] mt-3 font-[300] leading-[24px]">
          Get expert support from your dedicated nutritionist and psychologist
        </p>
      </div>
    </div>
  )
}

export default WellBeing
