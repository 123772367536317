import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
})

const makeRequest = async (method, url, params, data) => {
  const headers = {
    'api-key': process.env.REACT_APP_API_KEY,
  }

  //   const authToken = localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN)
  //   if (authToken) {
  //     const tokenDetails = JSON.parse(authToken)
  //     headers['access-token'] = `${tokenDetails?.token}`
  //   }

  try {
    const response = await instance({
      method,
      url,
      params,
      data,
      headers,
    })

    return response.data
  } catch (error) {
    handleApiError(error)
    // throw error
  }
}

const handleApiError = (error) => {
  if (axios.isCancel(error)) {
    // Request was canceled
    console.warn('Request canceled:', error.message)
  } else if (error.response) {
    // The request was made, but the server responded with an error
    console.error('API error - Status:', error.response.status)
    console.error('Response data:', error.response.data)
    console.error('Headers:', error.response.headers)
  } else if (error.request) {
    // The request was made, but no response was received
    console.error('No response received. Request:', error.request)
  } else {
    // Something happened in setting up the request that triggered an error
    console.error('Error setting up request:', error.message)
  }
}

export default makeRequest
