import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import usePayments from '../../hooks/usePayments'

import './style.css'
import { Box, Button, TextField } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import MaleIcon from '../../assets/icons/male.png'
import FemaleIcon from '../../assets/icons/female.png'
import OthersIcon from '../../assets/icons/others.png'
import Banner from '../../assets/images/tatva-care-banner.png'

import {
  getMedicalConditions,
  getPincodeDetails,
  submitPatientDetails,
} from '../../api'
import { getDateStr, getUrlParams } from '../../utils/helpers/common'
import { analytics, trackEvent } from '../../analytics'
import { EVENTS } from '../../analytics/events'
import {
  addressRegex,
  characterWithSpaceRegex,
  emailRegex,
  mobileNumberRegex,
} from '../../utils/helpers/regex'

const Form = () => {
  const [fields, setFields] = useState({
    medicalCondition: '',
    fullName: '',
    mobile: '',
    email: '',
    dob: '',
    gender: '',
    houseNo: '',
    street: '',
    pincode: '',
  })
  const [errors, setErrors] = useState({
    medicalCondition: '',
    fullName: '',
    mobile: '',
    email: '',
    dob: '',
    gender: '',
    houseNo: '',
    street: '',
    pincode: '',
  })
  const [pincodeResponse, setPincodeResponse] = useState(null)
  const [medicalConditions, setMedicalConditions] = useState([])

  //   const { handlePayment } = usePayments()

  const requiredFeilds = [
    'medicalCondition',
    'fullName',
    'mobile',
    'email',
    'dob',
    'gender',
    'houseNo',
    'street',
    'pincode',
  ]

  const getLocationDetails = async (pincode) => {
    const locationDetails = await getPincodeDetails({ pincode })

    if (locationDetails?.status) {
      setPincodeResponse(locationDetails?.data)
    } else {
      if (locationDetails?.status === 0) {
        setErrors({ ...errors, pincode: locationDetails?.message })
      }
      setPincodeResponse({})
    }
  }

  const handleChange = (event) => {
    let fieldName = event.target.name
    let fieldValue = event.target.value

    const newFields = { ...fields }
    const newErrors = { ...errors }

    switch (fieldName) {
      case 'fullName':
        if (!fieldValue.trim()) {
          newErrors[fieldName] = 'Full Name is required'
          newFields[fieldName] = fieldValue.trim()
        } else {
          if (characterWithSpaceRegex.test(fieldValue)) {
            newFields[fieldName] = fieldValue
          }
          newErrors[fieldName] = ''
        }
        break

      case 'mobile':
        if (!fieldValue.trim()) {
          newErrors[fieldName] = 'Mobile Number is required'
          newFields[fieldName] = fieldValue.trim()
        } else if (/^\d*$/.test(fieldValue)) {
          if (fieldValue.length === 10) {
            if (mobileNumberRegex.test(fieldValue)) {
              newErrors[fieldName] = ''
            } else {
              newErrors[fieldName] = 'Invalid Mobile Number'
            }
          } else {
            newErrors[fieldName] = 'Mobile Number must be 10 digits'
          }
          newFields[fieldName] = fieldValue
        }
        break

      case 'email':
        if (!fieldValue.trim()) {
          newErrors[fieldName] = 'Email is required'
          newFields[fieldName] = fieldValue.trim()
        } else if (emailRegex.test(fieldValue)) {
          newErrors[fieldName] = ''
        } else {
          newErrors[fieldName] = 'Invalid Email'
        }
        newFields[fieldName] = fieldValue
        break

      case 'houseNo':
        newFields[fieldName] = fieldValue
        if (!fieldValue.trim()) {
          newErrors[fieldName] = 'House Number is required'
          newFields[fieldName] = fieldValue
        } else {
          if (addressRegex.test(fieldValue) && fieldValue?.length > 200) {
            newErrors[fieldName] = 'Should not exceed more than 200'
          } else {
            newErrors[fieldName] = ''
          }
        }
        break

      case 'street':
        newFields[fieldName] = fieldValue
        if (!fieldValue.trim()) {
          newErrors[fieldName] = 'Street Name is required'
        } else {
          if (addressRegex.test(fieldValue) && fieldValue?.length > 200) {
            newErrors[fieldName] = 'Should not exceed more than 200'
          } else {
            newErrors[fieldName] = ''
          }
        }
        break

      case 'pincode':
        if (!fieldValue.trim()) {
          newErrors.pincode = 'Pincode is required'
          newFields[fieldName] = fieldValue.trim()
        } else if (/^\d*$/.test(fieldValue)) {
          if (!/^\d{6}$/.test(fieldValue)) {
            newErrors.pincode = 'Pincode must be 6 digits'
          } else {
            getLocationDetails(fieldValue)
            newErrors.pincode = ''
          }
          newFields[fieldName] = fieldValue
        }
        break

      default:
        newFields[fieldName] = fieldValue
        break
    }

    setFields({ ...newFields })
    setErrors({ ...newErrors })
  }

  const handleDobChange = (value) => {
    const eighteenYearsAgo = new Date()
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)

    setFields({ ...fields, dob: value })

    if (!value) {
      setErrors({ ...errors, dob: 'Required!' })
    } else if (value > eighteenYearsAgo) {
      setErrors({ ...errors, dob: 'Must be at least 18 years old!' })
    } else {
      setErrors({ ...errors, dob: '' })
    }

    trackEvent(EVENTS.TAP_ON_FIELD, {
      screenName: 'Register Screen',
      fieldName: 'dateOfBirth',
      fieldValue: value ? getDateStr(value) : '',
      event: 'Filling Form', //  This is specific to GA
    })
    trackEvent(EVENTS.FOUCUS_OUT_OF_FIELD, {
      screenName: 'Register Screen',
      fieldName: 'dateOfBirth',
      fieldValue: value ? getDateStr(value) : '',
      event: 'Filling Form',
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    requiredFeilds.map((fieldName) => {
      if (!fields[fieldName]) {
        errors[fieldName] = 'Required!'
      }
    })

    setErrors({ ...errors })

    const hasError = requiredFeilds.some((fieldName) => !!errors[fieldName])
    if (!hasError) {
      const urlParams = getUrlParams()

      const paylod = {
        ...urlParams,
        // plan_master_id: 'cc5bbfc4-a56a-11ee-893e-0022486e34ba',
        // plan_package_duration_rel_id: '417187e6-a56b-11ee-893e-0022486e34ba',
        // source: 'MARKETING%20CAMPAIGN',
        ...pincodeResponse,
        address_type: 'Home',
        // city: 'Bangalore',
        // state: 'Karnataka',
        // country: 'India',
        // latitude: '89.99',
        // longitude: '79.92',
        contact_no: fields.mobile,
        email: fields.email,
        name: fields.fullName,
        address: fields.houseNo,
        street: fields.street,
        pincode: fields.pincode,
        gender: fields.gender,
        dob: getDateStr(fields.dob),
        medical_condition_group_id: fields.medicalCondition,
        redirect: 0,
      }

      trackEvent(EVENTS.TAP_PROCEED_TO_PAYMENT, {
        screenName: 'Register Screen',
        event: 'Submitting Form',
        ...paylod,
      })

      analytics.setUserProperties(paylod)

      const response = await submitPatientDetails(paylod)

      if (response?.status === 'success') {
        analytics.logout()
        window.location.href = response?.data
      }

      //   handlePayment({
      //     amount: 50000, //  500 => 5
      //     name: fields?.fullName,
      //     mobile: fields?.mobile,
      //     email: 'demo@demo.com',
      //     handleSuccess: (data) => {
      //       console.log('razorpay success', data)
      //     },
      //     handleError: (error) => {
      //       console.log('razorpay error', error)
      //     },
      //   })
    }
  }

  //   useEffect(() => {
  //     const script = document.createElement('script')
  //     script.src = 'https://checkout.razorpay.com/v1/checkout.js'
  //     script.async = true
  //     document.body.appendChild(script)
  //   }, [])

  const getMedicalConditionsDetails = async () => {
    const response = await getMedicalConditions()

    if (response?.status) {
      setMedicalConditions(response?.data)
    }
  }

  useEffect(() => {
    getMedicalConditionsDetails()
  }, [])

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '100%' },
        paddingBottom: '20px',
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <img className="w-full" src={Banner} alt="banner" />

      <h1 className="text-2xl md:text-4xl font-semibold my-5">
        Welcome to the MyTatva
      </h1>
      <p className="text-gray-500">
        Take the first step in your journey towards improved health outcome
        <br />
        <br />
        Share the details below for MyTatva to know you better
      </p>

      <div className={'text-base font-semibold my-3'}>Contact Details</div>

      <TextField
        id="fullName"
        name="fullName"
        label="Your Name *"
        onChange={handleChange}
        value={fields.fullName}
        error={errors.fullName}
        helperText={errors.fullName}
        fullWidth
        sx={{ marginBottom: 2 }}
        onClick={() =>
          trackEvent(EVENTS.TAP_ON_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'fullName',
            event: 'Filling Form',
          })
        }
        onBlur={(event) =>
          trackEvent(EVENTS.FOUCUS_OUT_OF_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'fullName',
            fieldValue: event.target.value,
            event: 'Filling Form',
          })
        }
      />

      <TextField
        id="mobile"
        name="mobile"
        label="Mobile Number *"
        onChange={handleChange}
        value={fields.mobile}
        error={errors.mobile}
        helperText={errors.mobile}
        fullWidth
        sx={{ marginBottom: 2 }}
        onClick={() =>
          trackEvent(EVENTS.TAP_ON_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'mobile',
            event: 'Filling Form',
          })
        }
        onBlur={(event) =>
          trackEvent(EVENTS.FOUCUS_OUT_OF_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'mobile',
            fieldValue: event.target.value,
            event: 'Filling Form',
          })
        }
      />

      <TextField
        id="email"
        name="email"
        label="Email *"
        onChange={handleChange}
        value={fields.email}
        error={errors.email}
        helperText={errors.email}
        fullWidth
        sx={{ marginBottom: 2 }}
        onClick={() =>
          trackEvent(EVENTS.TAP_ON_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'email',
            event: 'Filling Form',
          })
        }
        onBlur={(event) =>
          trackEvent(EVENTS.FOUCUS_OUT_OF_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'email',
            fieldValue: event.target.value,
            event: 'Filling Form',
          })
        }
      />

      <TextField
        id="houseNo"
        name="houseNo"
        label="House Number and Building *"
        onChange={handleChange}
        value={fields.houseNo}
        error={errors.houseNo}
        helperText={errors.houseNo}
        fullWidth
        sx={{ marginBottom: 2 }}
        onClick={() =>
          trackEvent(EVENTS.TAP_ON_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'houseNo',
            event: 'Filling Form',
          })
        }
        onBlur={(event) =>
          trackEvent(EVENTS.FOUCUS_OUT_OF_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'houseNo',
            fieldValue: event.target.value,
            event: 'Filling Form',
          })
        }
      />

      <TextField
        id="street"
        name="street"
        label="Street *"
        onChange={handleChange}
        value={fields.street}
        error={errors.street}
        helperText={errors.street}
        fullWidth
        sx={{ marginBottom: 2 }}
        onClick={() =>
          trackEvent(EVENTS.TAP_ON_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'street',
            event: 'Filling Form',
          })
        }
        onBlur={(event) =>
          trackEvent(EVENTS.FOUCUS_OUT_OF_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'street',
            fieldValue: event.target.value,
            event: 'Filling Form',
          })
        }
      />

      <TextField
        id="pincode"
        name="pincode"
        label="Pincode *"
        onChange={handleChange}
        value={fields.pincode}
        error={errors.pincode}
        helperText={errors.pincode}
        fullWidth
        sx={{ marginBottom: 2 }}
        onClick={() =>
          trackEvent(EVENTS.TAP_ON_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'pincode',
            event: 'Filling Form',
          })
        }
        onBlur={(event) =>
          trackEvent(EVENTS.FOUCUS_OUT_OF_FIELD, {
            screenName: 'Register Screen',
            fieldName: 'pincode',
            fieldValue: event.target.value,
            event: 'Filling Form',
          })
        }
      />
      <TextField
        id="city"
        name="city"
        label="City *"
        InputLabelProps={{ shrink: true }}
        // onChange={handleChange}
        value={pincodeResponse?.city}
        // error={errors.city}
        // helperText={errors.city}
        disabled
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        id="state"
        name="state"
        label="State *"
        InputLabelProps={{ shrink: true }}
        // onChange={handleChange}
        value={pincodeResponse?.state}
        // error={errors.state}
        // helperText={errors.state}
        disabled
        fullWidth
        sx={{ marginBottom: 2 }}
      />

      <div className={'text-base font-semibold my-3'}>Medical Details</div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']} sx={{ marginBottom: 2 }}>
          <DatePicker
            label="Date of Birth *"
            onChange={handleDobChange}
            value={fields.dob}
            format="DD/MM/YYYY"
            slotProps={{
              textField: {
                error: errors.dob,
                helperText: errors.dob,
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>

      <div
        className={classNames(
          'mb-2',
          errors.gender ? 'text-red-500' : 'text-gray-600',
        )}
      >
        Gender * <span className="text-xs">{errors.gender}</span>
      </div>

      <Box
        component="div"
        sx={{
          '& .MuiTextField-root': { width: '100%' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 2,
          marginBottom: 2,
        }}
      >
        <Button
          variant="outlined"
          color={fields.gender === 'M' ? 'primary' : 'secondary'}
          sx={{ borderRadius: 2, paddingRight: 3 }}
          onClick={() => {
            setFields({ ...fields, gender: 'M' })
            setErrors({ ...errors, gender: '' })
            trackEvent(EVENTS.CLICK, {
              screenName: 'Register Screen',
              fieldName: 'gender',
              fieldValue: 'Male',
              event: 'Filling Form',
            })
          }}
          // fullWidth
        >
          <img src={MaleIcon} width="24px" height="24px" alt="male-icon" />
          Male
        </Button>
        <Button
          variant="outlined"
          color={fields.gender === 'F' ? 'primary' : 'secondary'}
          sx={{ borderRadius: 2, paddingRight: 3 }}
          onClick={() => {
            setFields({ ...fields, gender: 'F' })
            setErrors({ ...errors, gender: '' })
            trackEvent(EVENTS.CLICK, {
              screenName: 'Register Screen',
              fieldName: 'gender',
              fieldValue: 'Female',
              event: 'Filling Form',
            })
          }}
          // fullWidth
        >
          <img src={FemaleIcon} width="24px" height="24px" alt="male-icon" />
          Female
        </Button>
        {/* <Button
          variant="outlined"
          color={fields.gender === 'P' ? 'primary' : 'secondary'}
          sx={{ borderRadius: 2 }}
          onClick={() => {
            setFields({ ...fields, gender: 'P' })
            setErrors({ ...errors, gender: '' })
            trackEvent(EVENTS.CLICK, {
              screenName: 'Register Screen',
              fieldName: 'gender',
              fieldValue: 'Other',
            })
          }}
          fullWidth
        >
          <img src={OthersIcon} width="24px" height="24px" alt="male-icon" />
          Others
        </Button> */}
      </Box>

      <div
        className={classNames(
          'my-3',
          errors.medicalCondition ? 'text-red-500' : 'text-gray-600',
        )}
      >
        Medical Condition *
        <span className="text-xs">{errors.medicalCondition}</span>
      </div>

      <div className="grid grid-cols-4 gap-4">
        {medicalConditions?.map((condition) => (
          <button
            key={condition?.medical_condition_name}
            className={'flex flex-col items-center'}
            type="button"
            onClick={() => {
              setFields({
                ...fields,
                medicalCondition: condition?.medical_condition_group_id,
              })
              setErrors({
                ...errors,
                medicalCondition: '',
              })
              trackEvent(EVENTS.CLICK, {
                screenName: 'Register Screen',
                fieldName: 'medical_condition',
                fieldValue: condition?.medical_condition_group_id,
                conditionName: condition?.medical_condition_name,
                event: 'Filling Form',
              })
            }}
          >
            <div
              className={classNames(
                'border border-gray-500 rounded-2xl p-3 bg-purple-100',
                fields.medicalCondition ===
                  condition?.medical_condition_group_id
                  ? 'border-primary bg-purple-300'
                  : '',
              )}
              style={{
                border:
                  fields.medicalCondition ===
                  condition?.medical_condition_group_id
                    ? '1px solid #8c2fdf'
                    : '1px solid white',
              }}
            >
              <img
                src={
                  fields.medicalCondition ===
                  condition?.medical_condition_group_id
                    ? condition?.selected_imagess
                    : condition?.unselected_imagess
                }
                alt={condition?.medical_condition_name}
                className=""
                width={'100px'}
                height={'100px'}
              />
            </div>
            <span
              className={classNames(
                'text-sm mt-2',
                fields.medicalCondition ===
                  condition?.medical_condition_group_id
                  ? 'text-primary'
                  : '',
              )}
            >
              {condition?.medical_condition_name}
            </span>
          </button>
        ))}
      </div>

      <Box component="div" sx={{ marginTop: 3 }}>
        <Button variant="contained" type="submit" fullWidth>
          Proceed to Payment
        </Button>
      </Box>
    </Box>
  )
}

export default Form
