import makeRequest from './api'
import endpoints from './endpoints'

export const submitPatientDetails = (params) => {
  return makeRequest('GET', endpoints.SUBMIT_PATIENT_DETAILS, params)
}

export const getPincodeDetails = (params) => {
  return makeRequest('GET', endpoints.GET_PINCODE_DETAILS, params)
}

export const getMedicalConditions = () => {
  return makeRequest('GET', endpoints.GET_MEDICAL_CONDITIONS)
}

export const requestCallback = (data) => {
  return makeRequest('POST', endpoints.REQUEST_CALLBACK, {}, data)
}
