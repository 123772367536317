import React from 'react'
import { colors } from '../../../constants/colors'

import HealthRecordImage from '../../../assets/images/health-records.png'
import EducationImage from '../../../assets/images/educational-content.png'
import BookDiscountedDiagnosticsImage from '../../../assets/images/health-record.png'
import NutritionistImage from '../../../assets/images/nutritionist.png'

const OurProgram = () => {
  const ourOfferings = [
    {
      title: 'Nutritionist Session',
      desc: 'Get personalized guidance from nutritionists to improve your health',
      image: NutritionistImage,
    },
    {
      title: 'Book Discounted Diagnostics',
      desc: 'Get essential health diagnostics at exclusive discounted rates for a healthier you',
      image: BookDiscountedDiagnosticsImage,
    },
    {
      title: 'Educational Content',
      desc: 'Access customized content to support your health and wellness goals',
      image: EducationImage,
    },
    {
      title: 'Health Record Management',
      desc: 'Securely store and access all your medical records easily, retrieve them whenever you need',
      image: HealthRecordImage,
    },
  ]
  return (
    <section className="px-[38px] py-[41px]">
      <h2
        className={`text-[${colors.TEXT_PRIMARY}] text-[32px] leading-[42px] font-bold tracking-[-1px]`}
      >
        Get our Care
        <br /> Program for{' '}
        <span className={`text-[${colors.TEXT_GREEN}]`}>free</span> with
        Degrelax Purchase
      </h2>
      <h3 className="text-[24px] leading-[42px] font-bold tracking-[-1px] py-10">
        Program Offerings
      </h3>
      <div className="flex flex-col gap-[40px]">
        {ourOfferings?.map((offering, index) => (
          <div key={`prostate-care-offerings-${index}`} className="">
            <img
              src={offering.image}
              alt={offering.title}
              className="mt-[8px] relative right-5 w-358"
            />
            <h4 className="pl-[1.5px] mt-[12px] text-[20px] text-[#1F2933] font-[600] leading-[26px]">
              {offering.title}
            </h4>
            <p className="text-[16px] text-[#5A6774] mt-3 font-[300] leading-[24px]">
              {offering.desc}
            </p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default OurProgram
