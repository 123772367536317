import React, { useState } from 'react'
import UPIcon from '../../assets/images/Chevron_up.png'
import CloseIcon from '../../assets/images/Chevron_Down.png'

const Accordion = ({ title, answer, index }) => {
  const [accordionOpen, setAccordionOpen] = useState(false)
  const [accordionClick, setAccordionClick] = useState(false)

  return (
    <>
      <div className={`p-[16px] bg-[#F2F2F2] border-1 rounded-2xl mt-[16px]`}>
        <button
          onClick={() => {
            setAccordionClick(true)
            setAccordionOpen(!accordionOpen)
          }}
          className="flex justify-between w-full"
        >
          <div className="text-[20px] font-[600] text-[#8077B7] leading-[24px] text-left">
            {title}
          </div>
          <img
            src={
              accordionOpen
                ? CloseIcon
                : index === 1 && !accordionClick
                  ? CloseIcon
                  : UPIcon
            }
            width={'50px'}
          />
        </button>
        <div
          className={`grid mt-3 overflow-hidden transition-all duration-300 ease-in-out text-slate-600 ${
            accordionOpen
              ? 'grid-rows-[1fr] opacity-100'
              : index === 1 && !accordionClick
                ? 'grid-rows-[1fr] opacity-100'
                : 'grid-rows-[0fr] opacity-0'
          }`}
        >
          <div className="overflow-hidden font-[300] leading-[21px] text-[17px] text-[#5A6774]">
            {answer}
          </div>
        </div>
      </div>
    </>
  )
}

export default Accordion
