import React from 'react'

import ProstateCareJourneyImg from '../../../assets/images/programs/prostate-care/prostate-care-journey.png'

const ProstateCareJourney = () => {
  return (
    <div className="px-4">
      <img src={ProstateCareJourneyImg} alt="Prostate Care Journey" />
    </div>
  )
}

export default ProstateCareJourney
