import React, { memo, useEffect } from 'react'
import { getUrlParams } from '../../utils/helpers/common'
import { trackEvent } from '../../analytics'
import { EVENTS } from '../../analytics/events'

const InvalidPage = () => {
  useEffect(() => {
    const urlParams = getUrlParams()
    trackEvent(EVENTS.PAGE_VIEW, {
      screenName: 'Invalid/Error Screen',
      ...urlParams,
    })
  }, [])
  return (
    <div>
      <p className="text-red-800">Invalid url or link Expired</p>
    </div>
  )
}

export default memo(InvalidPage)
